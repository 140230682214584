import { APP_INITIALIZER, ApplicationModule, NgModule, Optional, SkipSelf } from '@angular/core';
import { SharedModule } from '@dash/randy/shared/shared.module';
import { LoginComponent } from './components/login/login.component';
import { authInterceptorProviders } from './helpers/auth.interceptor';
import { RouterModule } from '@angular/router';
import { LogoutComponent } from './components/logout/LogoutComponent';
import { LocalePipe } from './helpers/locale.pipe';
import { LogService } from '@dash/randy/shared/classes/log.service';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { CallbackComponent } from './components/callback/callback.component';
import { environment } from '../../../environments/environment';
import { CognitoService } from '@dash/randy/security/service/cognito.service';
import { RoleService } from '@dash/randy/security/service/role.service';
import { RoleSelectionComponent } from './components/role-selection/role-selection.component';

// We need a factory, since localStorage is not available during AOT build time.
export const storageFactory = (): OAuthStorage => localStorage;

@NgModule({
  declarations: [LoginComponent, LogoutComponent, LocalePipe, CallbackComponent, RoleSelectionComponent],
  imports: [
    SharedModule,
    RouterModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: environment.aws.allowedApiUrls,
        sendAccessToken: true,
      },
    }),
    ApplicationModule,
  ],
  exports: [LoginComponent, LogoutComponent, LocalePipe, RoleSelectionComponent],
  providers: [LocalePipe, { provide: OAuthStorage, useFactory: storageFactory }],
})
export class SecurityModule {
  constructor(private logService: LogService, @Optional() @SkipSelf() parentModule?: SecurityModule) {
    logService.debug('Security INITIALIZER');
    if (parentModule) {
      // throw new Error('GreetingModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(): any {
    return {
      ngModule: SecurityModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          useFactory: (service: CognitoService) => async () => {
            const res = await service.init();
            console.log('CognitoService initialized', res);
            return res;
          },
          deps: [CognitoService],
          multi: true,
        },
        {
          provide: APP_INITIALIZER,
          useFactory: (service: RoleService) => () => service.init(),
          deps: [RoleService],
          multi: true,
        },
        authInterceptorProviders,
      ],
    };
  }
}
