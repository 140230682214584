import { Component, OnInit } from '@angular/core';
import { CognitoService } from '@dash/randy/security/service/cognito.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss'],
})
export class CallbackComponent implements OnInit {
  constructor(private cognitoService: CognitoService, private route: ActivatedRoute, private router: Router) {
    console.log('Callback component');

    this.route.url.subscribe(async params => {
      const path = params[1].path;
      switch (path) {
        case 'login':
          await this.handleLogin();
          break;
        case 'logout':
          console.log('Signing out of cognito via the callback?');
          const isSignedOut = this.cognitoService.isAuthenticated();
          if (!isSignedOut) {
            await this.cognitoService.signOut();
          } else {
            this.router.navigate(['/login']).then(() => {
              console.log('Callback navigated to login page');
            });
          }
          break;
      }
    });
  }

  ngOnInit(): void {
    console.log('Received callback');
    const params = this.route.snapshot.queryParams;
    console.log(params, 'Route params');
  }

  private async handleLogin() {
    console.log('Handle login');
    await this.cognitoService.handleCallBack();
    // this.router.navigate(['/dashboard', 'home']);
    this.router.navigate(['/choose-application']).then(() => {});
  }
}
